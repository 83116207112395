import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PrimeTimeSection from '../components/PrimeTimeSection'
import PublishingSection from '../components/PublishingSection'
import TrainingSection from '../components/TrainingSection'
import RefSection from '../components/RefSection'
import TeamSection from '../components/TeamSection'
import ContactSection from '../components/ContactSection'

const IndexPage = ({ data }) => {
  const { frontmatter } = data.metadata
  const { image } = frontmatter
  return (
    <Layout subtitle={frontmatter.subtitle}>
      <div className=""
    style={{
      backgroundImage: `url(${
        !!image.childImageSharp ? image.childImageSharp.fluid.src : image
      })`,
      backgroundPosition: `top left`,
      backgroundRepeat: `repeat`,
      backgroundAttachment: `fixed`,
    }}>

      <PrimeTimeSection data={data.primetime} pattern={frontmatter.cardPattern} />
      <PublishingSection data={data.publishing} />
      <TrainingSection data={data.training} />
      <RefSection data={data.refs} />
      <TeamSection data={data.team} />
      <ContactSection title={data.contact.frontmatter.title} col1={data.contact.frontmatter.col1} col2={data.contact.frontmatter.col2} />
    </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    metadata: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cardPattern {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
      }
    }
    primetime: markdownRemark(frontmatter: { templateKey: { eq: "primetime-content" } }) {
      frontmatter {
        title
      }
      html
    }
    publishing: markdownRemark(frontmatter: { templateKey: { eq: "publishing-content" } }) {
      frontmatter {
        title
      }
      htmlAst
    }
    training: markdownRemark(frontmatter: { templateKey: { eq: "training-content" } }) {
      frontmatter {
        title
      }
      htmlAst
    }
    refs: markdownRemark(frontmatter: { templateKey: { eq: "refs-content" } }) {
      frontmatter {
        title
      }
      html
    }
    team: markdownRemark(frontmatter: { templateKey: { eq: "team-content" } }) {
      frontmatter {
        title
      }
      htmlAst
    }
    contact: markdownRemark(frontmatter: { templateKey: { eq: "contact-content" } }) {
      frontmatter {
        title
        col1
        col2
      }
    }
  }
`
