import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

const Section = ({ data }) => {
  const {html, frontmatter} = data
  const {title} = frontmatter
  return (
     <section className="section section--gradient">
        <div className="container">
            <div className="columns">
              <div className="column is-12">
                <div className="box">
                <div className="section">
                  <div className="content">
                    <h1 className="title has-text-weight-bold has-text-black">{title}</h1>
                    <div dangerouslySetInnerHTML={{__html: html}} />
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Section