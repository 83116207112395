import React from "react";
import { graphql } from "gatsby";
import Card from "./Card";

import B4logo from "../img/b4pub-logo.png";
import TSlogo from "../img/ts-logo.png";

const Section = ({ data, pattern }) => {
  const { html, frontmatter } = data;
  const { title } = frontmatter;
  return (
    <section id="prime-time" className="section section--gradient">
      <div className="container">
        <div className="box">
          <div className="section">
            <div className="content">
              <h1 className="title has-text-weight-bold has-text-black">
                {title}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="content">
              <div className="level">
                <div className="level-item has-text-centered">
                  <figure class="image">
                    <img
                      className="section-logo"
                      src={B4logo}
                      alt="B4Publishing Logo"
                      style={{ width: "260px", marginRight: "auto" }}
                    />
                  </figure>
                </div>
                <div class="level-item has-text-centered">
                  <figure class="image">
                    <img
                      className="section-logo"
                      src={TSlogo}
                      alt="The Training Station Logo"
                      style={{ width: "300px", marginRight: "auto" }}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
