import React from "react";
import ReactCardFlip from "react-card-flip";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  handleColor(color) {
    if (color === "Bleu") return { backgroundColor: "rgba(126,154,191,0.2)" };
    else if (color === "Rouge")
      return { backgroundColor: "rgba(255,209,209,0.4)" };
    else if (color === "Jaune")
      return { backgroundColor: "rgba(250,240,109,0.2)" };
    else return { backgroundColor: "transparent" };
  }

  render() {
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection="horizontal"
      >
        <div key="front" className="card">
          <div
            className={`box card-title `}
            style={this.handleColor(this.data.color)}
          >
            <div className="section">
              <h1 className="title has-text-weight-bold has-text-black">
                {this.data.title}
              </h1>
            </div>
            <div className="section">
              <h2 className="title is-4 has-text-black has-text-weight-semibold">
                {this.data.subtitle}
              </h2>
            </div>
            <footer class="card-footer">
              <a onClick={this.handleClick}>
                <span className="icon is-large">
                  <i class="fas fa-ellipsis-h"></i>
                </span>
              </a>
            </footer>
          </div>
        </div>

        <div key="back" className="card">
          <div
            className="box card-title"
            style={this.handleColor(this.data.color)}
          >
            <div className="section">
              <p className="has-text-black">{this.data.content}</p>
            </div>
            <footer class="card-footer">
              <a onClick={this.handleClick}>
                <span className="icon is-large">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </footer>
          </div>
        </div>
      </ReactCardFlip>
    );
  }
}

export default Card;
