import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Card from './Card'

class CardRoll extends React.Component {
  render() {
    const { data: data } = this.props
    const { markdownRemark: remark } = data
    const { frontmatter: frontmatter } = remark
    const { cards: cards } = frontmatter

    return (
      <div className="card-roll columns is-multiline">
        {cards &&
          cards.map((node, index) => (
            <div className="column is-4 is-flex" key={index}>
              <Card data={node}></Card>
            </div>
          ))}
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query CardRollQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "publishing-content" } }) {
          frontmatter {
            cards {
              title
              subtitle
              content
              color
            }
          }
        }
      }
    `}
    render={(data) => <CardRoll data={data} />}
  />
)