import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const Section = ({ title, col1, col2 }) => (
  <section id="contact" className="section section--gradient">
    <div className="container">
      <div className="box">
        <div className="section">
          <div className="content">
            <div className="tile">
              <h1 className="title has-text-weight-bold has-text-black">
                {title}
              </h1>
            </div>
            <div className="columns">
              <div className="column is-half">
                <div className="tile">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(col1)
                        .toString()
                    }}
                  />
                </div>
              </div>
              <div className="column is-half">
                <div className="tile">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(recommended)
                        .use(remarkHtml)
                        .processSync(col2)
                        .toString()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Section;
