import React from "react";
import rehypeReact from "rehype-react";
import CardRoll from "./team-card-roll";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { cartes: CardRoll }
}).Compiler;

const Section = ({ data }) => {
  const { htmlAst, frontmatter } = data;
  const { title } = frontmatter;
  return (
    <section id="equipe" className="section section--gradient">
      <div className="container">
        <div className="box">
          <div className="section">
            <div className="content ">
                <h1 className="title has-text-weight-bold has-text-black">
                  {title}
                </h1>
            </div>
            <div>{renderAst(htmlAst)}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
