import React from "react";
import rehypeReact from "rehype-react";
import CardRoll from "./training-card-roll";
import Logo from "../img/ts-logo.png";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { cartes: CardRoll }
}).Compiler;

const Section = ({ data }) => {
  const { htmlAst, frontmatter } = data;
  const { title } = frontmatter;
  return (
    <section id="training-station" className="section section--gradient">
      <div className="container">
        <div className="box">
          <div className="section">
            <div className="content level">
              <div class="level-left">
                <div className="level-item">
                  <h1 className="title has-text-weight-bold has-text-black" style={{ marginRight: "auto" }}>
                    {title}
                  </h1>
                </div>
                <div className="level-item">
                  <img src={Logo} alt="The Training Station Logo" style={{ width: "110px", marginRight: "auto" }} />
                </div>
              </div>
            </div>
            <div>{renderAst(htmlAst)}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
